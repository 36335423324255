import React from "react";
import { Box, Container, Toolbar, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F0C334",
      contrastText: "#000",
    },
    secondary: {
      main: "#176828",
    },
  },
});

export default function Banner() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Toolbar />
        <Box
          sx={{
            bgcolor: "#F0C334",
            textAlign: "center",
            py: "50px",
            width: "100%",
          }}
        >
          <Container maxWidth="xl">
            <Typography variant="h2" fontWeight={600}>
              B2B
            </Typography>
            <Typography variant="h4" fontWeight={600}>
              Market Place
            </Typography>
          </Container>
        </Box>
      </ThemeProvider>
    </div>
  );
}
