import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function CurrenceySelect() {
  const [currencey, setCurrencey] = React.useState('');

  const handleChange = (event) => {
    setCurrencey(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 80 }}>
        {/* <InputLabel id="demo-simple-select-autowidth-label">KWD</InputLabel> */}
        <Select
        //  labelId="demo-simple-select-autowidth-label"
       //   id="demo-simple-select-autowidth"
          value={currencey}
          onChange={handleChange}
          autoWidth
       //   label="Currencey"
          size='small'
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          <MenuItem value={10}>KWD</MenuItem>
          <MenuItem value={21}>AED</MenuItem>
          <MenuItem value={22}>INR</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

