import React from "react";
import {
  Tab,
  Tabs,
  createTheme,
  ThemeProvider,
  Box,
  Grid,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import whatsapp_icon2 from "../images/whatsapp_icon2.png";
import call_Icon2 from "../images/call_Icon2.png";
import sms_icon2 from "../images/sms_icon2.png";
import footer_logo from "../images/footer_logo.png";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#daaf23",
    },
    secondary: {
      main: "#6E798C",
    },
  },
});

export default function Footer() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1, bgcolor: "black", color: "white", mt: 3 }}>
          <Container>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              pb={5}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Box sx={{ flexGrow: 1 }}>
                  <Link to="/">
                    <Box
                      component={"img"}
                      p={1}
                      width={120}
                      src={footer_logo}
                      alt=""
                      to="/"
                    />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={2} sm={4} md={6} textAlign="right">
                <Stack direction="row" spacing={1} justifyContent="end">
                  <IconButton aria-label="delete">
                    <Img alt="complex" src={whatsapp_icon2} />
                  </IconButton>
                  <IconButton aria-label="delete" disabled color="primary">
                    <Img alt="complex" src={call_Icon2} />
                  </IconButton>
                  <IconButton color="secondary" aria-label="add an alarm">
                    <Img alt="complex" src={sms_icon2} />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} >
                <Stack direction="row" spacing={1} justifyContent={{ xs: "center", sm: "center", md: "left"}}>
                  <IconButton aria-label="delete">
                    <FacebookOutlinedIcon sx={{ color: "#DFDFDF" }} />
                  </IconButton>
                  <IconButton aria-label="delete">
                    <TwitterIcon sx={{ color: "#DFDFDF" }} />
                  </IconButton>
                  <IconButton color="secondary" aria-label="add an alarm">
                    <InstagramIcon sx={{ color: "#DFDFDF" }} />
                  </IconButton>
                  <IconButton color="secondary" aria-label="add an alarm">
                    <LinkedInIcon sx={{ color: "#DFDFDF" }} />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={6} textAlign={{ xs: "center", sm: "center", md: "left"}}>
                <Typography variant="p" fontWeight={600} gutterBottom>
                  © 2022 BuyCorps. All rights reserved.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} textAlign={{ xs: "center", sm: "center", md: "right"}}>
                <Typography variant="p" fontWeight={600} gutterBottom>
                  Terms & Conditions
                </Typography>{" "}
              </Grid>
              <Grid item xs={12} sm={12} md={2} textAlign={{ xs: "center", sm: "center", md: "right"}}>
                <Typography variant="p" fontWeight={600} gutterBottom>
                  Privacy Policy
                </Typography>{" "}
              </Grid>
            </Grid>
          </Container>
        </Box>
      </ThemeProvider>
    </div>
  );
}
