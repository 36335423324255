import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import {  Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import CountrySelect from "./CountrySelect";
import SearchSelect from "./SearchSelect";
import logo from "../images/logo.png";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#000",
    },
    secondary: {
      main: "#176828",
    },
  },
});
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: "flex-start",
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  "@media all": {
    minHeight: 128,
  },
}));

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

export default function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Link to="/">
          <Box component={"img"} p={1} width={120} src={logo} alt="" to="/" />
        </Link>
      </Box>

      <Divider />

      <Link to="/HomeProduct">
        <List>
          Shop
          <ListItem disablePadding></ListItem>
        </List>
      </Link>
      <Link to="">
        <List>
          Special Order
          <ListItem disablePadding></ListItem>
        </List>
      </Link>

      <Box className="btn_bg">
        <Link to="/Profile">
          <Button
            variant="standard"
            color="#000"
            startIcon={<PersonIcon sx={{ color: "#000" }} />}
          >
            Your Profile
          </Button>
        </Link>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <AppBar component="nav">
            <Container maxWidth="xl">
              <Toolbar>
                <Box sx={{ flexGrow: 1 }}>
                  <Link to="/">
                    <Box
                      component={"img"}
                      p={1}
                      width={140}
                      src={logo}
                      alt=""
                      to="/"
                    />
                  </Link>
                </Box>

                <Box
                  className="btn_bg"
                  sx={{ display: { xs: "none", sm: "block" } }}
                >
                  <Link to="/HomeProduct">
                    <Button sx={{ color: "#000", textDecoration: "none" }}>
                      Shop
                    </Button>
                  </Link>
                  <Link to="">
                    <Button sx={{ color: "#000" }}>Special Order</Button>
                  </Link>

                  <Link to="/Profile">
                    <Button
                      variant="standard"
                      color="#000"
                      startIcon={<PersonIcon sx={{ color: "#000" }} />}
                    >
                      Your Profile
                    </Button>
                  </Link>
                </Box>
                <CountrySelect />
                <SearchSelect />
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ ml: 0, alignSelf: "flex-end", display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
              </Toolbar>
            </Container>
          </AppBar>
          <Box component="nav">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        </Box>
      </ThemeProvider>
    </div>
  );
}
