import React from "react";
import {
  Box,
  CardMedia,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import coffee1 from "../images/coffee1.png";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
export default function Orders() {
  return (
    <div>
      <Typography variant="h6" fontWeight={600} gutterBottom>
        Current Orders{" "}
      </Typography>

      {Array.from(Array(2)).map((_, index) => (
        <Grid item xs={12} sm={6} md={6} key={index} mb={2}>
          <Link to="">
            <Paper
              sx={{
                p: 2,
                margin: "auto",
                maxWidth: 500,
                flexGrow: 1,
                bgcolor: "#ECECEC",
                // backgroundColor: (theme) =>
                //   theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <ButtonBase sx={{ width: 128, height: 128 }}>
                    <Img alt="complex" src={coffee1} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontWeight={600}
                        component="div"
                        color="textSecondary"
                      >
                        Item
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontWeight={600}
                        component="div"
                      >
                        Coffee 1
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Lorem ipsum dolor, sit amet consectetur fugit inventore
                        quod facilis. Voluptatibus, ut sapiente. Minima aperiam
                        consectetur sint similique.
                      </Typography>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      color="textSecondary"
                      component="div"
                    >
                      Quantity
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      1000KG
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>
      ))}

      <Typography variant="h6" fontWeight={600} gutterBottom>
        Previous Orders{" "}
      </Typography>

      {Array.from(Array(2)).map((_, index) => (
        <Grid item xs={12} sm={6} md={6} key={index} mb={2}>
          <Link to="">
            <Paper
              sx={{
                p: 2,
                margin: "auto",
                maxWidth: 500,
                flexGrow: 1,
                bgcolor: "#ECECEC",
                // backgroundColor: (theme) =>
                //   theme.palette.mode === "dark" ? "#1A2027" : "#fff",
              }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <ButtonBase sx={{ width: 128, height: 128 }}>
                    <Img alt="complex" src={coffee1} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontWeight={600}
                        component="div"
                        color="textSecondary"
                      >
                        Item
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontWeight={600}
                        component="div"
                      >
                        Coffee 1
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Lorem ipsum dolor, sit amet consectetur fugit inventore
                        quod facilis. Voluptatibus, ut sapiente. Minima aperiam
                        consectetur sint similique.
                      </Typography>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      color="textSecondary"
                      component="div"
                    >
                      Quantity
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      1000KG
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>
      ))}
    </div>
  );
}
