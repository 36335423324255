import { Box, Toolbar, Typography } from '@mui/material'
import React from 'react'
import Header from '../header/Header'

export default function Settings() {
  return (
    <div>
      <Header />
      <Box component="main" sx={{ p: 3 }}> 
        <Toolbar />
        <Typography>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus explicabo excepturi rem repellat! Error, molestias quibusdam ab totam in culpa, eaque, repudiandae aliquam fugiat adipisci architecto quidem expedita unde ratione!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus explicabo excepturi rem repellat! Error, molestias quibusdam ab totam in culpa, eaque, repudiandae aliquam fugiat adipisci architecto quidem expedita unde ratione!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus explicabo excepturi rem repellat! Error, molestias quibusdam ab totam in culpa, eaque, repudiandae aliquam fugiat adipisci architecto quidem expedita unde ratione!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus explicabo excepturi rem repellat! Error, molestias quibusdam ab totam in culpa, eaque, repudiandae aliquam fugiat adipisci architecto quidem expedita unde ratione!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus explicabo excepturi rem repellat! Error, molestias quibusdam ab totam in culpa, eaque, repudiandae aliquam fugiat adipisci architecto quidem expedita unde ratione!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus explicabo excepturi rem repellat! Error, molestias quibusdam ab totam in culpa, eaque, repudiandae aliquam fugiat adipisci architecto quidem expedita unde ratione!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus explicabo excepturi rem repellat! Error, molestias quibusdam ab totam in culpa, eaque, repudiandae aliquam fugiat adipisci architecto quidem expedita unde ratione!
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus explicabo excepturi rem repellat! Error, molestias quibusdam ab totam in culpa, eaque, repudiandae aliquam fugiat adipisci architecto quidem expedita unde ratione!

      </Typography>
      </Box>
      <Typography variant='p'>settings page</Typography>
      <h2>settings details</h2>
    </div>
  )
}
