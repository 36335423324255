import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import HomeProductGrid from "./HomeProductGrid";
import MarketIcons from "./MarketIcons";

export default function HomeProduct() {
  return (
    <div>
      <Header />
      <MarketIcons />
      <HomeProductGrid />
      <Footer />
    </div>
  );
}
