import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";

export default function SpecialOrders() {
  return (
    <div>
      <Typography variant="h6" fontWeight={600}>
        Special Orders{" "}
      </Typography>
      <Grid item xs={12} sm={6} md={6} mb={2}>
        <Link to="">
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              maxWidth: 500,
              flexGrow: 1,
              bgcolor: "#ECECEC",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      Request ID RE001258
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      06-08-2022
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Lorem ipsum dolor, sit amet consectetur fugit inventore
                      quod facilis. Voluptatibus, ut sapiente. Minima aperiam
                      consectetur sint similique.
                    </Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    component="div"
                  >
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        borderRadius: 50,
                        bgcolor: "#3AF034",
                        display: "inline-block",
                      }}
                    />{" "}
                    Completed
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={6} mb={2}>
        <Link to="">
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              maxWidth: 500,
              flexGrow: 1,
              bgcolor: "#ECECEC",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      Request ID RE001258
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      06-08-2022
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Lorem ipsum dolor, sit amet consectetur fugit inventore
                      quod facilis. Voluptatibus, ut sapiente. Minima aperiam
                      consectetur sint similique.
                    </Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    component="div"
                  >
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        borderRadius: 50,
                        bgcolor: "#F0C334",
                        display: "inline-block",
                      }}
                    />{" "}
                    Inprogress
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Link>
      </Grid>
      <Grid item xs={12} sm={6} md={6} mb={2}>
        <Link to="">
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              maxWidth: 500,
              flexGrow: 1,
              bgcolor: "#ECECEC",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      Request ID RE001258
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      06-08-2022
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Lorem ipsum dolor, sit amet consectetur fugit inventore
                      quod facilis. Voluptatibus, ut sapiente. Minima aperiam
                      consectetur sint similique.
                    </Typography>
                  </Grid>
                  <Grid item></Grid>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    component="div"
                  >
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        borderRadius: 50,
                        bgcolor: "#FA3E3E",
                        display: "inline-block",
                      }}
                    />{" "}
                    Failed
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Link>
      </Grid>
    </div>
  );
}
