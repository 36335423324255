import {
  Tab,
  Tabs,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import SpaIcon from "@mui/icons-material/Spa";
const theme = createTheme({
  palette: {
    primary: {
      main: "#daaf23",
    },
    secondary: {
      main: "#6E798C",
    },
  },
});

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
export default function Video() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Tabs
          value={value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          aria-label="icon position tabs example"
        >
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
          <Tab icon={<SpaIcon />} iconPosition="start" label="FOOD MARKET" />
        </Tabs>
      </ThemeProvider>
    </div>
  );
}
