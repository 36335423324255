import {
  Box,
  ButtonBase,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { minHeight } from "@mui/system";
import React from "react";
import { styled } from "@mui/material/styles";
import home_banner4 from "../images/home_banner4.png";
import home_banner5 from "../images/home_banner5.png";
import home_banner3 from "../images/home_banner3.png";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
export default function WhoWeAre() {
  return (
    <div>
      <Container>
        <Grid container py={5} spacing={5}>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h3" gutterBottom fontWeight={600}>
              Who We Are
            </Typography>
            <Typography variant="h6" gutterBottom>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Voluptas, possimus id facere reprehenderit ipsum officia dolores
              repellendus optio! Ad ut repellendus amet odit et commodi? Facilis
              dolorem libero velit impedit. Lorem ipsum dolor sit amet
              consectetur, adipisicing elit. Voluptas, possimus id facere
              reprehenderit ipsum officia dolores repellendus optio! Ad ut
              repellendus amet odit et commodi? Facilis dolorem libero velit
              impedit. Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Voluptas, possimus id facere reprehenderit ipsum officia dolores
              repellendus optio! Ad ut repellendus amet odit et commodi? Facilis
              dolorem libero velit impedit.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Card sx={{ flexGrow: 1 }}>
        <CardMedia
          component="img"
          //  height="140"
          image={home_banner3}
          alt="Buy Crops banner"
          className="home_banner2bg"
        />
      </Card>

      <Paper
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "center",
          color: "#ffffff",
          textAlign: "center",
          width: "100%",
           minHeight: { xs: "68vh", md: "85vh" },
        }}
        className="Project"
        style={{ backgroundImage: `url(${home_banner4})` }}
        elevation={0}
      >
        <Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            justifyContent= "center"
            alignItems= "center"
          >
            <Container sx={{ py: 25, textAlign: "justify" }} >
              <Grid item xs={12} md={6}>
                <Typography
                justifyContent= "center"
                alignItems="center"
                  variant="h6"
                  fontWeight={600}
                  gutterBottom
                  color="#1A191E"
                >
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                  Voluptas, possimus id facere reprehenderit ipsum officia
                  dolores repellendus optio! Ad ut repellendus amet odit et
                  commodi? Facilis dolorem libero velit impedit. Lorem ipsum
                  dolor sit amet consectetur, adipisicing elit. Voluptas,
                  possimus id facere reprehenderit ipsum officia dolores
                  repellendus optio! Ad ut repellendus amet odit et commodi?
                  Facilis dolorem libero velit impedit.
                </Typography>
              </Grid>
              <Grid item xs={6}></Grid>
            </Container>
          </Grid>
        </Box>
      </Paper>

      <Box sx={{ width: "100%", bgcolor: "#F6F6F6", py: 5 }}>
        <Container>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                //  height="140"
                image={home_banner5}
                alt="green iguana"
                bgcolor="#EFEFEF"
              />
            </Grid>
            <Grid item xs={12} md={6} alignSelf="center">
              <Typography
                variant="h6"
                px="16px"
                justifyContent="center"
                gutterBottom
                color="#1A191E"
                fontWeight={600}
              >
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Voluptas, possimus id facere reprehenderit ipsum officia dolores
                repellendus optio! Ad ut repellendus amet odit et commodi?
                Facilis dolorem libero velit impedit. Lorem ipsum dolor sit amet
                consectetur, adipisicing elit. Voluptas, possimus id facere
                reprehenderit ipsum officia dolores repellendus optio! Ad ut
                repellendus amet odit et commodi? Facilis dolorem libero velit
                impedit.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}
