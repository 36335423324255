import React from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import "../home/home.css";
import Banner from './Banner';
import Market from './Market';
import MarketIcons from './MarketIcons';
import Support from './Support';
import Video from './Video';
import WhoWeAre from './WhoWeAre';

export default function home() {
  return (
    <div>
      <Header />
      <Banner />
      <Video />
      <Market />
      <MarketIcons />
      <Support />
      <WhoWeAre />
    
      <Footer />
    </div>

  )
}
