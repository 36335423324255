import { Toolbar } from '@mui/material'
import React from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import ProductDetail from './ProductDetail'

export default function Product() {
  return (
    <div>
        <Header />
        <Toolbar />
        <ProductDetail />
        <Footer />
    </div>
  )
}
