import {
  Box,
  ButtonBase,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import whatsapp_icon1 from "../images/whatsapp_icon1.png";
import call_Icon1 from "../images/call_Icon1.png";
import sms_icon1 from "../images/sms_icon1.png";
import { styled } from "@mui/material/styles";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0C334",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  height: 90,
  width: 90,
  borderRadius: 100,
  color: theme.palette.text.primary,
  display: "flex",
  flexDirection: {
    xs: "column", // mobile
    sm: "row", // tablet and up
  },
}));

export default function Support() {
  const [spacing, setSpacing] = React.useState(2);

  const handleChange = (event) => {
    setSpacing(Number(event.target.value));
  };

  const jsx = `
<Grid container spacing={${spacing}}>
`;
  return (
    <div>
      <Grid sx={{ flexGrow: 1, pb: 5 }} container spacing={5}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h3" gutterBottom fontWeight={600}>
            Support
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={5}>
            <Grid item>
              <Paper
                sx={{
                  height: 90,
                  width: 90,
                  borderRadius: 50,
                  backgroundColor: "#F0C334",
                }}
              >
                <Img alt="complex" src={whatsapp_icon1} />{" "}
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                sx={{
                  height: 90,
                  width: 90,
                  borderRadius: 50,
                  backgroundColor: "#F0C334",
                }}
              >
                <Img alt="complex" src={call_Icon1} />{" "}
              </Paper>
            </Grid>
            <Grid item>
              <Paper
                sx={{
                  height: 90,
                  width: 90,
                  borderRadius: 50,
                  backgroundColor: "#F0C334",
                }}
              >
                <Img alt="complex" src={sms_icon1} />{" "}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
