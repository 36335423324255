import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import coffee1 from "../images/coffee1.png";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import {
  Box,
  Button,
  ThemeProvider,
  createTheme,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  IconButton,
} from "@mui/material";
import usa_flag from "../images/usa_flag.png";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#013A4B",
    },
    secondary: {
      main: "#6E798C",
    },
  },
});
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0C334",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  color: theme.palette.text.primary,
  boxShadow: 3,
  elevation: 8,
  minHeight: "106px",
  "&:hover": {
    backgroundColor: "primary.main",
    opacity: [0.9, 0.8, 0.7],
  },
}));
export default function HomeProductGrid() {
  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {Array.from(Array(6)).map((_, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <Link to="/Product">
                  <Paper
                    sx={{
                      p: 2,
                      margin: "auto",
                      maxWidth: 500,
                      flexGrow: 1,
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark" ? "#1A2027" : "#fff",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item>
                        <ButtonBase sx={{ width: 128, height: 128 }}>
                          <Img alt="complex" src={coffee1} />
                        </ButtonBase>
                      </Grid>
                      <Grid item xs={12} sm container>
                        <Grid item xs container direction="column" spacing={2}>
                          <Grid item xs>
                            <Typography
                              gutterBottom
                              variant="subtitle1"
                              fontWeight={600}
                              component="div"
                            >
                              Coffee 1
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                              Lorem ipsum dolor, sit amet consectetur fugit
                              inventore quod facilis. Voluptatibus, ut sapiente.
                              Minima aperiam consectetur sint similique.
                            </Typography>
                            <Typography
                              sx={{ cursor: "pointer" }}
                              variant="body2"
                            >
                              VIEW
                              <IconButton>
                                <TrendingFlatIcon />
                              </IconButton>
                            </Typography>
                          </Grid>
                          <Grid item></Grid>
                        </Grid>
                        <Grid item>
                          <Typography variant="subtitle1" component="div">
                            <img
                              loading="lazy"
                              width="20"
                              src={usa_flag}
                              alt=""
                            />
                            USA
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Grid container justifyContent="center" py={5} spacing={5}>
          <Grid item xs={12} sm={6} md={5}>
            <Box sx={{ boxShadow: 9 }}>
              <Item>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h4"
                    fontWeight={600}
                    component="div"
                  >
                    Product not Listed?
                  </Typography>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    color="text.primary"
                  >
                    Request your enquire
                  </Typography>
                </CardContent>
                <CardActions>
                  <ThemeProvider theme={theme}>
                    <Button variant="outlined">Request</Button>
                  </ThemeProvider>
                </CardActions>
              </Item>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
