// import React from "react";
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import product_detail1 from "../images/product_detail1.png";
import {
  Container,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import usa_flag from "../images/usa_flag.png";
import ind_flag from "../images/ind_flag.png";
import ShareIcon from "@mui/icons-material/Share";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CurrenceySelect from "../header/CurrenceySelect";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import Slider from "@mui/material/Slider";
import ksa_flag from "../images/ksa_flag.png";
import ind_flag2 from "../images/ind_flag2.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from "react-router-dom";
import CheckoutDialog from "../dialogs/CheckoutDialog";
const theme = createTheme({
  palette: {
    primary: {
      main: "#F0C334",
      contrastText: "#000",
    },
    secondary: {
      main: "#000",
      dot: "#DFDFDF",
    },
  },
});
function valuetext(value) {
  return `${value}°C`;
}

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    })
  );
}

const Demo = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function ProductDetail() {
  const [count, setCount] = useState(0);
  const IncNum = () => {
    setCount(count + 1);
  };
  const DecNum = () => {
    if (count > 0) setCount(count - 1);
    else {
      setCount(0);
      alert("min limit reached");
    }
  };

  const [shipment, setShipment] = React.useState("");

  const handleChange = (event) => {
    setShipment(event.target.value);
  };
  const [shipmentport, setShipmentport] = React.useState("");

  const handleChange2 = (event) => {
    setShipmentport(event.target.value);
  };

  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1, py: 5 }}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={12} md={5}>
                <CardMedia
                  component="img"
                  sx={{ borderRadius: 2 }}
                  image={product_detail1}
                  alt="BuyCrops product image"
                />
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    color="text.secondary"
                  >
                    Share product page{" "}
                    <IconButton>
                      <ShareIcon />
                    </IconButton>
                  </Typography>
                </CardContent>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  color="text.primary"
                  gutterBottom
                >
                  Additional Details
                </Typography>
                <Grid
                  container
                  xs={12}
                  sx={{ bgcolor: "#F5F5F5", p: 1, borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    Net weight
                  </Grid>
                  <Grid item xs={6}>
                    22.68kg
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{ p: 1 }}>
                  <Grid item xs={6}>
                    Type of packaging
                  </Grid>
                  <Grid item xs={6}>
                    Vacuum packaging and cardboard box
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sx={{ bgcolor: "#F5F5F5", p: 1, borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    Shelf life
                  </Grid>
                  <Grid item xs={6}>
                    24 months
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{ p: 1 }}>
                  <Grid item xs={6}>
                    Storage conditions
                  </Grid>
                  <Grid item xs={6}>
                    dark and cool place
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sx={{ bgcolor: "#F5F5F5", p: 1, borderRadius: 1 }}
                >
                  <Grid item xs={6}>
                    Energy value per 100 g of product:
                  </Grid>
                  <Grid item xs={6}>
                    552 kcal
                  </Grid>
                </Grid>
                <Grid container xs={12} sx={{ p: 1 }}>
                  <Grid item xs={6}>
                    Nutrition Facts (Food value) per 100 g of product:
                  </Grid>
                  <Grid item xs={6}>
                    Fats – 44 g; Proteins – 18 g; Carbohydrates – 30 g
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={7}
                alignSelf="center"
                height="auto"
                maxHeight="90vh"
                overflow="auto"
              >
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    fontWeight={600}
                    component="div"
                  >
                    Hazelnuts
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    color="text.secondary"
                  >
                    The product of : USA{" "}
                    <img loading="lazy" width="20" src={usa_flag} alt="" />
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    color="text.secondary"
                  >
                    Shipped from : IND{" "}
                    <img loading="lazy" width="20" src={ind_flag} alt="" />
                  </Typography>
                  <Typography
                    justifyContent="center"
                    alignItems="center"
                    variant="p"
                    fontWeight={600}
                    gutterBottom
                    color="#1A191E"
                  >
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Voluptas, possimus id facere reprehenderit ipsum officia
                    dolores repellendus optio! Ad ut repellendus amet odit et
                    commodi? Facilis dolorem libero velit impedit. Lorem ipsum
                    dolor sit amet consectetur, adipisicing elit. Voluptas,
                    possimus id facere reprehenderit ipsum officia dolores
                    repellendus optio! Ad ut repellendus amet odit et
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "right" }}>
                  <Button
                    size="small"
                    sx={{
                      color: "gray",
                      fontweight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    Read more <TrendingFlatIcon />
                  </Button>
                </CardActions>

                <Grid
                  container
                  xs={6}
                  sx={{ p: 1, justifyContent: "center", flexWrap: "wrap" }}
                >
                  <Grid item sm={10} md={11}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      color="text.primary"
                      gutterBottom
                    >
                      Min.10KG
                    </Typography>
                  </Grid>
                  <Grid item sm={2} md={1}>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      color="text.primary"
                      gutterBottom
                    >
                      Max.1000KG
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  xs={12}
                  sm={12}
                  md={9}
                  sx={{
                    justifyContent: "center",
                    flexWrap: "wrap",
                    border: "solid 1px gray",
                    p: 1,
                    borderRadius: 1,
                  }}
                >
                  <Grid item sm={3.7} md={5.4} alignSelf="center">
                    <Grid
                      container
                      xs
                      sx={{
                        bgcolor: "#EFEFEF",
                        p: 1,
                        width: "100%",
                        borderRadius: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <ThemeProvider theme={theme}>
                        <Stack spacing={2} direction="row">
                          <Tooltip title="Add">
                            <Button
                              onClick={IncNum}
                              variant="outlined"
                              color="secondary"
                              sx={{
                                bgcolor: "#fff",
                                p: 0,
                                m: 0,
                                border: "solid 1px #707070",
                              }}
                            >
                              <AddIcon sx={{ fontSize: 30 }} />
                            </Button>
                          </Tooltip>
                          <Typography variant="h4" fontWeight={600}>
                            {count}
                          </Typography>
                          <Button
                            onClick={DecNum}
                            variant="outlined"
                            color="secondary"
                            sx={{
                              bgcolor: "#fff",
                              p: 0,
                              m: 0,
                              border: "solid 1px #707070",
                            }}
                          >
                            <RemoveIcon sx={{ fontSize: 30 }} />
                          </Button>
                        </Stack>{" "}
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sm={1.3}
                    md={1.6}
                    sx={{ alignSelf: "center", fontWeight: 600 }}
                  >
                    In KG
                  </Grid>
                  <Grid item sm={7} md={5} sx={{ justifyContent: "center" }}>
                    <Typography> Estimated price</Typography>
                    <Stack spacing={0} direction="row">
                      <Typography
                        variant="h4"
                        fontWeight={600}
                        display="inline-block"
                      >
                        0.000{" "}
                      </Typography>
                      <CurrenceySelect />
                    </Stack>
                  </Grid>
                </Grid>

                <Grid
                  container
                  xs={12}
                  md={9}
                  sx={{ py: 5, justifyContent: "center" }}
                >
                  <Grid item sx={12}>
                    <Typography
                      variant="h6"
                      fontWeight={600}
                      color="text.primary"
                      gutterBottom
                      textAlign="center"
                    >
                      Specified Quantities
                    </Typography>
                    <Stack spacing={2} direction="row">
                      <Button variant="contained" sx={{ fontWeight: 600 }}>
                        100 KG
                      </Button>
                      <Button variant="outlined" color="secondary">
                        300 KG
                      </Button>
                      <Button variant="outlined" color="secondary">
                        600 KG
                      </Button>
                      <Button variant="outlined" color="secondary">
                        1000 KG
                      </Button>
                    </Stack>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    md={12}
                    sx={{ py: 5, justifyContent: "center" }}
                  >
                    <Grid item sx={12}>
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        color="text.primary"
                        gutterBottom
                      >
                        Shipment Container
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      sx={{ p: 1, justifyContent: "center", flexWrap: "wrap" }}
                    >
                      <Grid item sm={6} md={6}>
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          color="text.primary"
                          gutterBottom
                          textAlign="center"
                        >
                          20 Feet
                        </Typography>
                      </Grid>
                      <Grid item sm={6} md={6}>
                        <Typography
                          variant="subtitle1"
                          fontWeight={600}
                          color="text.primary"
                          gutterBottom
                          textAlign="center"
                        >
                          40 Feet
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container xs={12} sm={12} md={12}>
                      <Grid item sm={3.7} md={5.4} alignSelf="center">
                        <Grid
                          container
                          xs
                          sx={{
                            bgcolor: "#F5F5F5",
                            border: "solid 1px gray",
                            p: 1,
                            width: "100%",
                            borderRadius: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Stack spacing={3} direction="row">
                              <Tooltip title="Add">
                                <Button
                                  onClick={IncNum}
                                  variant="outlined"
                                  color="secondary"
                                  sx={{
                                    bgcolor: "#fff",
                                    p: 0,
                                    m: 0,
                                    border: "solid 1px #DFDFDF",
                                  }}
                                >
                                  <AddIcon sx={{ fontSize: 30 }} />
                                </Button>
                              </Tooltip>
                              <Typography variant="h4" fontWeight={600}>
                                {count}
                              </Typography>
                              <Button
                                onClick={DecNum}
                                variant="outlined"
                                color="secondary"
                                sx={{
                                  bgcolor: "#fff",
                                  p: 0,
                                  m: 0,
                                  border: "solid 1px #DFDFDF",
                                }}
                              >
                                <RemoveIcon sx={{ fontSize: 30 }} />
                              </Button>
                            </Stack>{" "}
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                      <Grid item sm={1}></Grid>

                      <Grid item sm={3.7} md={5.4} alignSelf="center">
                        <Grid
                          container
                          xs
                          sx={{
                            bgcolor: "#F5F5F5",
                            border: "solid 1px gray",
                            p: 1,
                            width: "100%",
                            borderRadius: 1,
                            justifyContent: "space-between",
                          }}
                        >
                          <ThemeProvider theme={theme}>
                            <Stack spacing={3} direction="row">
                              <Tooltip title="Add">
                                <Button
                                  onClick={IncNum}
                                  variant="outlined"
                                  color="secondary"
                                  sx={{
                                    bgcolor: "#fff",
                                    p: 0,
                                    m: 0,
                                    border: "solid 1px #DFDFDF",
                                  }}
                                >
                                  <AddIcon sx={{ fontSize: 30 }} />
                                </Button>
                              </Tooltip>
                              <Typography variant="h4" fontWeight={600}>
                                {count}
                              </Typography>
                              <Button
                                onClick={DecNum}
                                variant="outlined"
                                color="secondary"
                                sx={{
                                  bgcolor: "#fff",
                                  p: 0,
                                  m: 0,
                                  border: "solid 1px #DFDFDF",
                                }}
                              >
                                <RemoveIcon sx={{ fontSize: 30 }} />
                              </Button>
                            </Stack>{" "}
                          </ThemeProvider>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      xs={12}
                      md={12}
                      sx={{ py: 5, justifyContent: "center" }}
                    >
                      <Grid item sx={12}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="text.primary"
                          gutterBottom
                          display="block"
                        >
                          Product price
                        </Typography>
                      </Grid>

                      <Grid item sm={12} md={12}>
                        <Stack
                          spacing={0}
                          direction="row"
                          sx={{ justifyContent: "center", pb: 5 }}
                        >
                          <Grid
                            container
                            xs={6}
                            sx={{
                              border: "solid 1px gray",
                              p: 1,
                              borderRadius: 1,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="h4"
                              fontWeight={600}
                              display="inline-block"
                            >
                              0.000{" "}
                            </Typography>
                            <CurrenceySelect />
                          </Grid>
                        </Stack>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        xs={12}
                        sx={{
                          bgcolor: "#F5F5F5",
                          py: 5,
                          borderRadius: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Grid item sx={12}>
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            color="text.primary"
                            gutterBottom
                            display="block"
                          >
                            Shipment
                          </Typography>
                          <Stack spacing={10} py={5} direction="row">
                            <Button
                              variant="contained"
                              startIcon={<DirectionsBoatFilledIcon />}
                              color="primary"
                              sx={{ fontWeight: 600 }}
                            >
                              Sea
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<FlightTakeoffIcon />}
                              color="secondary"
                              sx={{ fontWeight: 600 }}
                            >
                              Air
                            </Button>
                            <Button
                              variant="outlined"
                              startIcon={<LocalShippingIcon />}
                              color="secondary"
                              sx={{ fontWeight: 600 }}
                            >
                              Land
                            </Button>
                          </Stack>
                        </Grid>

                        <Grid container spacing={3} justifyContent="center">
                          <Grid item xs={2}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                            >
                              <Grid item xs={1}>
                                <img
                                  loading="lazy"
                                  width="20"
                                  sx={{
                                    border: "solid 2px #302828",
                                    borderRadius: "50%",
                                  }}
                                  src={ind_flag2}
                                  alt=""
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <Slider
                                  defaultValue={90}
                                  orientation="vertical"
                                  valueLabelDisplay="auto"
                                  sx={{
                                    color: "text.primary",
                                    minHeight: 200,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <img
                                  loading="lazy"
                                  width="20"
                                  border="solid 2px #302828"
                                  borderRadius="50%"
                                  src={ksa_flag}
                                  alt=""
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={9}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="start"
                              textAlign="start"
                              spacing={4}
                            >
                              <Grid item xs={3}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                  component="div"
                                  color="textSecondary"
                                >
                                  Shipping from
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={600}
                                  component="div"
                                >
                                  INDIA
                                </Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography
                                  variant="subtitle2"
                                  fontWeight={600}
                                  component="div"
                                  color="textSecondary"
                                >
                                  Estimated time
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={600}
                                  component="div"
                                >
                                  20 Days
                                </Typography>
                              </Grid>

                              <Grid item xs={3}>
                                <Grid container spacing={3}>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight={600}
                                      component="div"
                                      color="textSecondary"
                                    >
                                      Shipping to
                                    </Typography>

                                    <FormControl fullWidth size="small">
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={shipment}
                                        onChange={handleChange}
                                      >
                                        <MenuItem value={10}>
                                          Saudi Arabia
                                        </MenuItem>
                                        <MenuItem value={20}>UAE</MenuItem>
                                        <MenuItem value={30}>USA</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight={600}
                                      component="div"
                                      color="textSecondary"
                                    >
                                      Shipping port
                                    </Typography>

                                    <FormControl fullWidth size="small">
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={shipmentport}
                                        onChange={handleChange2}
                                      >
                                        <MenuItem value={10}>
                                          Dammam port
                                        </MenuItem>
                                        <MenuItem value={20}>KSA port</MenuItem>
                                        <MenuItem value={30}>
                                          JEDDAH port
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  sx={12}
                                  justifyContent="center"
                                  py={3}
                                >
                                  <Typography
                                    variant="h6"
                                    fontWeight={600}
                                    color="text.primary"
                                    gutterBottom
                                    display="block"
                                  >
                                    Shipment by sea price: 300 KD
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item sm={12} md={12}>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="text.primary"
                          gutterBottom
                          display="block"
                          textAlign="center"
                          pt={5}
                          pb={2}
                        >
                          Final Price
                        </Typography>

                        <Stack
                          spacing={0}
                          direction="row"
                          sx={{ justifyContent: "center", pb: 5 }}
                        >
                          <Grid
                            container
                            xs={6}
                            sx={{
                              border: "solid 1px gray",
                              p: 1,
                              borderRadius: 1,
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="h4"
                              fontWeight={600}
                              display="inline-block"
                            >
                              0.000
                            </Typography>
                            <CurrenceySelect />
                          </Grid>
                        </Stack>
                        <Stack
                          spacing={3}
                          py={5}
                          direction="row"
                          justifyContent="center"
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ fontWeight: 600 }}
                          >
                            Full Payment
                          </Button>
                          <Button variant="outlined" color="secondary">
                            Full Payment
                          </Button>
                          <Button variant="outlined" color="secondary">
                            Order Sample
                          </Button>
                        </Stack>
                        <Typography
                          variant="h6"
                          fontWeight={600}
                          color="text.primary"
                          gutterBottom
                          display="block"
                          textAlign="center"
                          pt={5}
                          pb={5}
                        >
                          Negotiate the Price
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        xs={12}
                        sx={{
                          bgcolor: "#F5F5F5",
                          //   border: "solid 1px gray",
                          py: 5,
                          //    width: "100%",
                          borderRadius: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Grid container spacing={3} justifyContent="center">
                          <Grid item xs={2}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="center"
                            >
                              <Grid item xs={1}>
                                <FiberManualRecordIcon
                                  color="#DFDFDF"
                                  sx={{
                                    border: "solid 4px #707070",
                                    borderRadius: 50,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <Slider
                                  defaultValue={50}
                                  orientation="vertical"
                                  sx={{
                                    color: "text.primary",
                                    minHeight: 90,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <FiberManualRecordIcon
                                  color="primary"
                                  sx={{
                                    border: "solid 4px #707070",
                                    borderRadius: 50,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={9}>
                            <Grid
                              container
                              direction="column"
                              justifyContent="start"
                              textAlign="start"
                              spacing={5}
                            >
                              <Grid item xs={3}>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  fontWeight={600}
                                  component="div"
                                >
                                  I requested 300 kg for 180 KWD
                                </Typography>
                              </Grid>

                              <Grid item xs={6}></Grid>

                              <Grid item xs={3}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      fontWeight={600}
                                      component="div"
                                    >
                                      Seller negotiated 300 kg for 210 KWD
                                    </Typography>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      sx={{ fontWeight: 600 }}
                                    >
                                      Accept
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid>
                        <Stack
                          spacing={3}
                          py={5}
                          direction="row"
                          justifyContent="center"
                        >
                          <TextField
                            id="filled-basic"
                            label="Add your price here"
                            size="small"
                            variant="filled"
                          />
                          <TextField
                            id="filled-basic"
                            label="KG"
                            variant="filled"
                            size="small"
                          />
                          {/* <CheckoutDialog /> */}
                          <Link to="/Checkout"> 
                          <Button
                            variant="outlined"
                            color="primary"
                            size="large"
                            sx={{
                              color: "black",
                              fontWeight: 600,
                              minWidth: 100,
                            }}
                          >
                            Send
                          </Button></Link>
                        </Stack>
                        <Grid item sm={12} md={12}>
                          <Typography
                            variant="h6"
                            fontWeight={600}
                            color="text.primary"
                            gutterBottom
                            display="block"
                            textAlign="center"
                            pt={5}
                            pb={5}
                          >
                            Product terms & Conditions
                          </Typography>
                        </Grid>

                        <Grid>
                          <Grid item xs>
                            <Grid
                              sx={{
                                border: "solid 1px gray",
                                borderRadius: 1,
                                padding: 2,
                              }}
                            >
                              <Typography variant="h6" component="div">
                                Lists
                              </Typography>
                              <Demo>
                                <List>
                                  {generate(
                                    <ListItem>
                                      <ListItemIcon>
                                        <FiberManualRecordIcon fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aliquam tincidunt mauris eu risus."
                                        secondary={
                                          secondary ? "Secondary text" : null
                                        }
                                      />
                                    </ListItem>
                                  )}
                                </List>
                              </Demo>
                            </Grid>
                          </Grid>
                          <CardActions>
                            <Button
                              sx={{
                                color: "gray",
                                fontweight: 600,
                                textTransform: "capitalize",
                              }}
                            >
                              Detailed View T&C <TrendingFlatIcon />
                            </Button>
                          </CardActions>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </ThemeProvider>
    </div>
  );
}
