import { Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import Slider from "@mui/material/Slider";
import ksa_flag from "../images/ksa_flag.png";
import ind_flag2 from "../images/ind_flag2.png";

export default function Tracking() {
  return (
    <div>
      <Typography variant="h6" fontWeight={600}>
        Tracking
      </Typography>

      <Grid item xs={12} sm={12} md={12} mb={2}>
        <Link to="">
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              flexGrow: 1,
              bgcolor: "#ECECEC",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontWeight={600}
                        component="div"
                      >
                        Request ID RE001258
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontWeight={600}
                        component="div"
                      >
                        06-08-2022
                      </Typography>
                      <Grid item xs={12} alignItems="center" sm container>
                        <Grid item xs={1}>
                          <img
                            loading="lazy"
                            width="20"
                            sx={{
                              border: "solid 2px #302828",
                              borderRadius: "50%",
                            }}
                            src={ind_flag2}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Slider
                            defaultValue={30}
                            sx={{
                              color: "text.primary",
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} textAlign="end">
                          <img
                            loading="lazy"
                            width="20"
                            border="solid 2px #302828"
                            borderRadius="50%"
                            src={ksa_flag}
                            alt=""
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          borderRadius: 50,
                          bgcolor: "#3AF034",
                          display: "inline-block",
                        }}
                      />
                      Completed
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm
                  container
                  minWidth={{ sx: 300, sm: 400, md: 500 }}
                >
                  <Grid item xs={4}>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      fontWeight={600}
                      component="div"
                      color="textSecondary"
                    >
                      Shipping from
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      fontWeight={600}
                      component="div"
                    >
                      INDIA
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      fontWeight={600}
                      component="div"
                      color="textSecondary"
                    >
                      Estimated time
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      fontWeight={600}
                      component="div"
                    >
                      20 Days
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      fontWeight={600}
                      component="div"
                      color="textSecondary"
                    >
                      Shipping to
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      fontWeight={600}
                      component="div"
                      //  display="inline-block"
                    >
                      Saudi Arabia
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Link>
      </Grid>
      <Grid item xs={12} sm={12} md={12} mb={2}>
        <Link to="">
          <Paper
            sx={{
              p: 2,
              margin: "auto",
              //  minWidth: 500,
              flexGrow: 1,
              bgcolor: "#ECECEC",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} sm container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontWeight={600}
                        component="div"
                      >
                        Request ID RE001258
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="subtitle1"
                        fontWeight={600}
                        component="div"
                      >
                        06-08-2022
                      </Typography>
                      <Grid item xs={12} alignItems="center" sm container>
                        <Grid item xs={1}>
                          <img
                            loading="lazy"
                            width="20"
                            sx={{
                              border: "solid 2px #302828",
                              borderRadius: "50%",
                            }}
                            src={ind_flag2}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <Slider
                            defaultValue={30}
                            sx={{
                              color: "text.primary",
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} textAlign="end">
                          <img
                            loading="lazy"
                            width="20"
                            border="solid 2px #302828"
                            borderRadius="50%"
                            src={ksa_flag}
                            alt=""
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      component="div"
                    >
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          borderRadius: 50,
                          bgcolor: "#F0C334",
                          display: "inline-block",
                        }}
                      />
                      In Transit
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm container>
                  <Grid item xs={4}>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      fontWeight={600}
                      component="div"
                      color="textSecondary"
                    >
                      Shipping from
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      fontWeight={600}
                      component="div"
                    >
                      INDIA
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      fontWeight={600}
                      component="div"
                      color="textSecondary"
                    >
                      Estimated time
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      fontWeight={600}
                      component="div"
                    >
                      20 Days
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      fontWeight={600}
                      component="div"
                      color="textSecondary"
                    >
                      Shipping to
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      fontWeight={600}
                      component="div"
                    >
                      Saudi Arabia
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Link>
      </Grid>
    </div>
  );
}
