import {
  Box,
  Container,
  Grid,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import Header from "../header/Header";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Footer from "../footer/Footer";
import { styled } from "@mui/material/styles";
import Orders from "../orders/Orders";
import SpecialOrders from "../orders/SpecialOrders";
import Tracking from "../orders/Tracking";
import AddressChangeDialog from "../dialogs/AddressChangeDialog";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Profile() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Header />
      <Toolbar />
      <Container>
        <Box
          sx={{
            flexGrow: 1,
            py: 5,
            bgcolor: "background.paper",
            display: "flex",
            minHeight: "69vh",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab label="Company Info" {...a11yProps(0)} />
            <Tab label="Orders" {...a11yProps(1)} />
            <Tab label="Special Orders" {...a11yProps(2)} />
            <Tab label="Tracking" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Typography variant="h6" fontWeight={600} gutterBottom>
              Current Address{" "}
            </Typography>
            <Grid container>
              <Grid item xs="12">
                <Typography
                  variant="p"
                  color="textSecondary"
                  fontWeight={600}
                  gutterBottom
                >
                  National LTD, +965 000 000 00
                </Typography>
              </Grid>
              <Grid item xs="12">
                <Typography
                  variant="p"
                  color="textSecondary"
                  fontWeight={600}
                  gutterBottom
                >
                  info@national.com{" "}
                </Typography>
              </Grid>
              <Grid item xs="12">
                <Typography
                  variant="p"
                  color="textSecondary"
                  fontWeight={600}
                  gutterBottom
                >
                  794 Broaddus Avenue
                </Typography>{" "}
              </Grid>
              <Grid item xs="12">
                <Typography
                  variant="p"
                  color="textSecondary"
                  fontWeight={600}
                  gutterBottom
                >
                  Paducah, Kentucky(KY), 42001
                </Typography>{" "}
              </Grid>
              <Grid item xs="12">
                <Typography
                  variant="p"
                  color="textSecondary"
                  fontWeight={600}
                  gutterBottom
                >
                  270-280-7966 USA
                </Typography>{" "}
              </Grid>
              <Grid item xs="12">
                <Typography
                  variant="p"
                  color="textSecondary"
                  fontWeight={600}
                  gutterBottom
                >
                  USA
                </Typography>{" "}
              </Grid>
              <AddressChangeDialog />
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Orders />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <SpecialOrders />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Tracking />
          </TabPanel>
        </Box>
      </Container>
      <Footer />
    </div>
  );
}
