import { Box, ButtonBase, Container, Grid } from "@mui/material";
import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import coffee from "../images/coffee.png";
import fruits from "../images/fruits.png";
import nuts from "../images/nuts.png";
import oil from "../images/oil.png";
import vegetables from "../images/vegetables.png";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: "#EFEFEF",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  justifyContent: "center",
  textAlign: "center",
  color: theme.palette.text.primary,
  display: "flex",
  flexDirection: {
    xs: "column", // mobile
    sm: "row", // tablet and up
  },
}));
export default function MarketIcons() {
  return (
    <div>
      <Container>
        <Box sx={{ flexGrow: 1, pt: 15, pb: 10 }}>
          <Grid container spacing={5} columns={{ xs: 12, sm: 12, md: 12 }}>
            <Grid item xs>
              <Link to="/HomeProduct">
                <Item2>
                  <ButtonBase sx={{ width: 95, height: 108 }}>
                    <Img alt="complex" src={coffee} />
                  </ButtonBase>
                </Item2>
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    component="div"
                    textAlign="center"
                  >
                    Coffee
                  </Typography>
                </CardContent>
              </Link>
            </Grid>
            <Grid item xs>
              <Link to="/HomeProduct">
                <Item2>
                  <ButtonBase sx={{ width: 95, height: 108 }}>
                    <Img alt="complex" src={vegetables} />
                  </ButtonBase>
                </Item2>
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    component="div"
                    textAlign="center"
                  >
                    Vegetables
                  </Typography>
                </CardContent>
              </Link>
            </Grid>
            <Grid item xs>
              <Link to="/HomeProduct">
                <Item2>
                  <ButtonBase sx={{ width: 95, height: 108 }}>
                    <Img alt="complex" src={fruits} />
                  </ButtonBase>
                </Item2>
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    component="div"
                    textAlign="center"
                  >
                    Fruits
                  </Typography>
                </CardContent>
              </Link>
            </Grid>
            <Grid item xs>
              <Link to="/HomeProduct">
                <Item2>
                  <ButtonBase sx={{ width: 95, height: 108 }}>
                    <Img alt="complex" src={nuts} />
                  </ButtonBase>
                </Item2>
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    component="div"
                    textAlign="center"
                  >
                    Nuts
                  </Typography>
                </CardContent>
              </Link>
            </Grid>
            <Grid item xs>
              <Link to="/HomeProduct">
                <Item2>
                  <ButtonBase sx={{ width: 95, height: 108 }}>
                    <Img alt="complex" src={oil} />
                  </ButtonBase>
                </Item2>
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    component="div"
                    textAlign="center"
                  >
                    Oil
                  </Typography>
                </CardContent>
              </Link>
            </Grid>
            <Grid item xs>
              <Link to="/HomeProduct">
                <Item2>
                  <ButtonBase sx={{ width: 95, height: 108 }}>
                    <Img alt="complex" src={fruits} />
                  </ButtonBase>
                </Item2>
                <CardContent>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    component="div"
                    textAlign="center"
                  >
                    Fruits
                  </Typography>
                </CardContent>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
