import * as React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Checkout from "./components/checkout/Checkout";
import Home from "./components/home/Home";
import HomeProduct from "./components/home/HomeProduct";
import Orders from "./components/orders/Orders";
import Login from "./components/pages/Login";
import Profile from "./components/pages/Profile";
import Settings from "./components/pages/Settings";
import Product from "./components/products/Product";

function App() {
  return (
    <div id="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/HomeProduct" element={<HomeProduct />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/Profile" element={<Profile />} />
          <Route path="/Orders" element={<Orders />} />
          <Route path="/Product" element={<Product />} />
          <Route path="/Checkout" element={<Checkout />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
function AuthRoute({ children, redirectTo }) {
  return localStorage.getItem("data") ? <Navigate to={redirectTo} /> : children;
}

function ProtectedRoute({ children, redirectTo }) {
  return localStorage.getItem("data") ? children : <Navigate to={redirectTo} />;
}
export default App;
