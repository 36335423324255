import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  ButtonBase,
  CardContent,
  Container,
  Typography,
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material";

import market1 from "../images/market1.png";
import market2 from "../images/market2.png";

const theme = createTheme({
  palette: {
    primary: {
      main: "#013A4B",
    },
    secondary: {
      main: "#6E798C",
    },
  },
});
//theme = responsiveFontSizes(theme);

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F0C334",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.primary,
  display: "flex",
  
  flexDirection: {
    xs: "column", // mobile
    sm: "row", // tablet and up
  },
  minHeight: "106px",
}));
const Item2 = styled(Paper)(({ theme }) => ({
  backgroundColor: "#EFEFEF",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.primary,
  display: "flex",
  flexDirection: {
    xs: "column", // mobile
    sm: "row", // tablet and up
  },
  minHeight: "106px",
}));

export default function Market() {
  return (
    <div>
      <Container>
          <Grid container justifyContent="center" pt={5} spacing={5} >
            <Grid item xs={12} sm={6} md={4}>
              <Item >
                <ButtonBase sx={{ width: 97, height: 90 }}>
                  <Img alt="complex" src={market1} />
                </ButtonBase>

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <Typography component="div" fontWeight="bold" variant="h5">
                      Local <br />
                      Market
                    </Typography>
                  </CardContent>
                </Box>
              </Item>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Item2>
                <ButtonBase sx={{ width: 97, height: 90 }}>
                  <Img alt="complex" src={market2} />
                </ButtonBase>

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <CardContent sx={{ flex: "1 0 auto" }}>
                    <ThemeProvider theme={theme}>
                      <Typography
                        component="div"
                        fontWeight="bold"
                        variant="h5"
                      >
                        International <br /> Market
                      </Typography>
                    </ThemeProvider>
                  </CardContent>
                </Box>
              </Item2>
            </Grid>
          </Grid>
      </Container>
    </div>
  );
}
